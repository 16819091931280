@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&family=Sora:wght@100..800&display=swap");
body {
  margin: 0;
  font-family: "sora";
}
/* input,
textarea {
  touch-action: manipulation;
} */

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.three-line-ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  /* Number of lines to show */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.two-line-ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* Number of lines to show */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.MuiModal-root:focus {
  outline: none;
}

.MuiModal-root .MuiBox-root:focus {
  outline: none;
}

.slick-slide {
  width: auto !important;

  /* or adjust as needed */
}

@keyframes originSlide {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-100%);
  }
}
.origins {
  overflow: hidden;
  padding: 10px 0px;
  /* background-color: white; */
  margin-top: 30px;
  white-space: nowrap;
  position: relative;
}
.remove-origins {
  overflow: hidden;
  padding: 10px 0px;
  /* background-color: white; */
  margin-top: 30px;
  white-space: nowrap;
  position: relative;
}
.remove-origins::before {
  left: 0;
  background: linear-gradient(
    to left,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0)
  );
}
.origins::before {
  left: 0;
  background: linear-gradient(to left, rgba(255, 255, 255, 0), #f6f7f8);
}
.origins::after {
  right: 0;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), #f6f7f8);
}
.remove-origins::after {
  right: 0;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), #f6f7f8);
}
.origins::before,
.origins::after {
  position: absolute;
  top: 0;

  width: 150px;
  height: 100%;

  content: "";
  z-index: 2;
}
.remove-origins::before,
.remove-origins::after {
  position: absolute;
  top: 0;

  width: 0px;
  height: 100%;

  content: "";
  z-index: 0;
}
.no-origins-slide {
  display: flex;
  display: inline-flex;
}

.origins-slide {
  display: flex;
  display: inline-flex;
  animation: 300s originSlide infinite linear;
}
.origins:hover .origins-slide {
  animation-play-state: paused;
}
.origins-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px 20px;
  width: 80px;
  cursor: pointer;
}
.origins-slide img,
.no-origins-slide img {
  height: 40px;
  /* margin: 0px 30px; */
}
